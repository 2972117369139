import * as React from "react"
import Password from "../components/Password"
import Timeline from "../components/Timeline/Timeline"

export default function Content({ items, loaded }) {
  const [hasAccess, setHasAccess] = React.useState(false)
  const [routeLoaded, setRouteLoaded] = React.useState(true)

  React.useEffect(() => {
    let ss = window.sessionStorage
    setHasAccess(ss.getItem("hvphnc-access") === "1")
  }, [])

  React.useEffect(() => {
    if (loaded && routeLoaded) {
      document.querySelector("#___gatsby").classList.add("loaded")
    } else {
      document.querySelector("#___gatsby").classList.remove("loaded")
    }
  }, [loaded, routeLoaded])

  const handleSetHasAccess = (val) => {
    setHasAccess(val);
    if (window) {
        let ss = window.sessionStorage;
        ss.setItem('hvphnc-access', val ? '1' : '0')
    }
  }

  return hasAccess ? (
    <Timeline items={items} />
  ) : (
    <Password setHasAccess={handleSetHasAccess} setRouteLoaded={setRouteLoaded} />
  )
}
