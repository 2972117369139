import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import * as React from "react"

import Layout from "../components/Layout"
import Content from "../components/Content"
import Seo from "../components/SEO"
import { getImage } from "gatsby-plugin-image"
import useMutationObserver from "@rooks/use-mutation-observer"

const IndexPage = ({ data }) => {
  const backgroundImage = getImage(data.file.childImageSharp)
  const [loaded, setLoaded] = React.useState(false)
  const timelineItems = data.cockpitTimeline.Timeline.value
  let body
  if (typeof window !== "undefined") {
    body = document.body
  }
  const handleBodyMutated = () => {
    if (
      body.classList.contains("wf-prenton--loaded") &&
      body.classList.contains("bg-image--loaded")
    ) {
      setLoaded(true)
    }
  }

  useMutationObserver(React.useRef(body), handleBodyMutated, {
    attributeFilter: ["class"],
  })

  return (
    <Layout
      backgroundImage={backgroundImage}
      onBackgroundLoad={() => {
        document.body.classList.add("bg-image--loaded")
      }}
    >
      <Seo
        title="Ready to explore the hidden stories of Hooverphonic?"
        lang="en"
      />
      <Content items={timelineItems} loaded={loaded} />
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query Timeline {
    cockpitTimeline {
      Timeline {
        value {
          value {
            button {
              value {
                link {
                  value
                }
                text {
                  value
                }
              }
            }
            bigImage {
              value
            }
            centered {
              value
            }
            content {
              value
            }
            date {
              value
            }
            image {
              value {
                url
                childImageSharp {
                  gatsbyImageData(
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                    width: 800
                  )
                }
              }
            }
            title {
              value
            }
            embed {
              value
            }
          }
        }
      }
    }
    file(relativePath: { eq: "background-image.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1600, webpOptions: { quality: 85 }, quality: 85)
      }
    }
  }
`
