import React, { useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import TimelineItem from "./TimelineItem"

export default function Timeline({ items }) {
  let mobileRight = true
  return (
    <div className="timeline-wrapper">
      <Container>
        <Row>
          <Col></Col>
          <Col md={12}>
            <div className="timeline-container">
              {items.slice(1).map((item, index) => {
                let position = "right"
                if (index % 2 === 0) {
                  position = "left"
                }
                const first = index === 0
                const last =
                  index === items.slice(1).length - 1
                if (item.value.centered?.value && index !== 0) {
                  mobileRight = !mobileRight
                }
                return (
                  <TimelineItem
                    key={index}
                    item={item}
                    position={position}
                    first={first}
                    last={last}
                    mobileRight={mobileRight}
                  />
                )
              })}
            </div>
          </Col>
          <Col></Col>
        </Row>
      </Container>
    </div>
  )
}
