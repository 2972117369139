import { useEffect } from "react"

export function useClickOutside(refs, callback) {
  useEffect(() => {
    function handleClickOutside(event) {
      refs.forEach(ref => {
        if (ref.current && !ref.current.contains(event.target)) {
          callback(event)
        }
      })
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [refs])
}
