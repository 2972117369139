import React, { useEffect, useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import { useBreakpoint } from "gatsby-plugin-breakpoints"

export default function Password({ setHasAccess, setRouteLoaded }) {
  const [value, setValue] = useState("")
  const [error, setError] = useState(false)

  const breakpoints = useBreakpoint()

  const handleAccess = () => {
    if (
      value.toLowerCase() === process.env.GATSBY_SITE_PASSWORD.toLowerCase()
    ) {
      setRouteLoaded(false)
      setTimeout(() => {
        setHasAccess(true)
        if (_paq)
          _paq.push(['trackEvent', 'password', 'accessGranted', value, 1000]);
        setRouteLoaded(true)
      }, 500)
    } else {
      if (_paq)
        _paq.push(['trackEvent', 'password', 'accessDenied', value, 1000]);
      setError(true)
    }
  }
  return (
    <div className="password-wrapper">
      <Container>
        <Row>
          <Col></Col>
          <Col md={10} lg={8}>
            <div className="content">
              <h2>Ready to explore some of our hidden stories?</h2>
              <h3>
                Take a closer look into our Spotify Canvases and find the
                correct password.
              </h3>
              <p>
                <input
                  onChange={e => {
                    setValue(e.target.value)
                    setError(false)
                  }}
                  value={value}
                  type="password"
                  placeholder={
                    breakpoints.l ? "Password" : "Fill in your password here"
                  }
                  onKeyDown={e => {
                    if (e.keyCode === 13) handleAccess()
                  }}
                  className={error ? "error" : ""}
                  autoFocus
                />
                <button onClick={handleAccess}>Enter</button>
              </p>
            </div>
          </Col>
          <Col></Col>
        </Row>
      </Container>
    </div>
  )
}
