import { faPlay, faTimes } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link } from "gatsby"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import React, { useEffect, useRef, useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import Modal from "react-modal"
import { formatLink } from "../../helpers/formatLink"
import { useClickOutside } from "../../helpers/useClickOutside"

function Loader() {
  return (
    <div className="lds-ellipsis">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  )
}

export default function TimelineItem({
  item,
  position,
  first,
  last,
  mobileRight,
}) {
  const [thumbnail, setThumbnail] = useState()
  const [isYoutubeEmbed, setIsYoutubeEmbed] = useState(true)
  const [iframeModalOpen, setIframeModalOpen] = useState(false)
  const [imageModalOpen, setImageModalOpen] = useState(false)

  useEffect(() => {
    const url = data.embed?.value.match(/(https?:\/\/[^ ]*)/)
    if (url && url[1].indexOf("youtube") !== -1) {
      const _url = url[1]
      setIsYoutubeEmbed(true)
      const image =
        _url
          .replace("https://www", "https://img")
          .replace("embed/", "vi/")
          .replace('"', "")
          .replace("'", "") + "/maxresdefault.jpg"
      setThumbnail(image)
    } else {
      setIsYoutubeEmbed(false)
    }
  }, [])

  const data = item.value
  const image = data.image ? getImage(data.image.value.childImageSharp) : null
  const renderDots = () => {
    if (first && data.centered.value) {
      return <div className="dot below"></div>
    } else if (last && data.centered.value) {
      return <div className="dot above"></div>
    } else if (!last && !first && data.centered.value) {
      return (
        <>
          <div className="dot above"></div>
          <div className="dot below"></div>
        </>
      )
    } else {
      return <div className="dot"></div>
    }
  }
  const handleModalOpened = () => {
    document.querySelector("html").style.overflow = "hidden"
  }
  const handleModalClosed = () => {
    document.querySelector("html").style.overflow = "auto"
  }

  const closeImageModal = e => {
    e.preventDefault()
    e.stopPropagation()
    setImageModalOpen(false)
  }

  const closeIframeModal = e => {
    e.preventDefault()
    e.stopPropagation()
    setIframeModalOpen(false)
  }

  const closeModals = e => {
    closeImageModal(e)
    closeIframeModal(e)
  }

  Modal.setAppElement("#___gatsby")

  const imageWrapperRef = useRef(null)
  const iframeWrapperRef = useRef(null)

  useClickOutside([iframeWrapperRef, imageWrapperRef], closeModals)
  return (
    <div
      className={[
        "timeline-item",
        data.centered.value ? "centered" : position,
        mobileRight ? "mobile-right" : "mobile-left",
      ].join(" ")}
    >
      <div
        className={["content", data.embed?.value ? "has-embed" : ""].join(" ")}
      >
        {first && !data.centered.value && <div className="dot first"></div>}
        {renderDots()}
        <div className="text">
          {(data.centered?.value || data.bigImage?.value) && (
            <div className="inline-date">
              <p>{data.date ? data.date.value : ""}</p>
            </div>
          )}
          {first && <h2>Congratulations!</h2>}
          <h4
            dangerouslySetInnerHTML={{
              __html: data.title?.value.replace(/\\n/g, "<br/>"),
            }}
          ></h4>
        </div>
        {image && (
          <Modal
            shouldCloseOnOverlayClick={true}
            onRequestClose={closeImageModal}
            isOpen={imageModalOpen}
            className="image-modal"
            onAfterOpen={handleModalOpened}
            onAfterClose={handleModalClosed}
          >
            <div className="modal__inner">
              <FontAwesomeIcon icon={faTimes} onClick={closeImageModal} />
              <div className="image-wrapper" ref={imageWrapperRef}>
                <GatsbyImage image={image} alt="" />
                <Loader />
              </div>
            </div>
          </Modal>
        )}
        {(image || data.embed?.value) &&
          (!data.bigImage?.value ? (
            <div className="image">
              <GatsbyImage
                image={image}
                onClick={() => setImageModalOpen(image)}
                placeholder="dominantColor"
                alt=""
              />
            </div>
          ) : (
            // These are the mobile iframes & images
            <>
              {!image && data.embed?.value && !isYoutubeEmbed && (
                <div
                  className="iframe-wrapper small"
                  dangerouslySetInnerHTML={{
                    __html: data.embed?.value,
                  }}
                ></div>
              )}
              {!image && data.embed?.value && isYoutubeEmbed && (
                <>
                  <div
                    className="iframe-thumbnail-wrapper small"
                    onClick={() => setIframeModalOpen(true)}
                  >
                    <img src={thumbnail} alt="" />
                    <FontAwesomeIcon icon={faPlay} />
                  </div>
                  <Modal
                    onRequestClose={closeIframeModal}
                    isOpen={iframeModalOpen}
                    className="iframe-modal"
                    onAfterOpen={handleModalOpened}
                    onAfterClose={handleModalClosed}
                    shouldCloseOnOverlayClick={true}
                  >
                    <Container>
                      <FontAwesomeIcon
                        icon={faTimes}
                        onClick={closeIframeModal}
                      />
                      <div
                        className="iframe-in-modal-wrapper"
                        ref={iframeWrapperRef}
                        dangerouslySetInnerHTML={{
                          __html: data.embed.value,
                        }}
                      ></div>
                      <Loader />
                    </Container>
                  </Modal>
                </>
              )}
              {image && (
                <div className="image mobile-image">
                  <GatsbyImage
                    image={image}
                    onClick={() => setImageModalOpen(image)}
                    placeholder="dominantColor"
                    alt=""
                  />
                </div>
              )}
            </>
          ))}
        {!(data.embed?.value && image) &&
          data.embed?.value &&
          !isYoutubeEmbed &&
          !data.bigImage.value && (
            <div
              className="iframe-wrapper"
              dangerouslySetInnerHTML={{
                __html: data.embed?.value,
              }}
            ></div>
          )}
        {!(data.embed?.value && image) &&
          data.embed?.value &&
          isYoutubeEmbed &&
          !data.bigImage.value && (
            <>
              <div
                className="iframe-thumbnail-wrapper"
                onClick={() => setIframeModalOpen(true)}
              >
                <img src={thumbnail} alt="" />
                <FontAwesomeIcon icon={faPlay} />
              </div>
              <Modal
                onRequestClose={closeIframeModal}
                isOpen={iframeModalOpen}
                className="iframe-modal"
                onAfterOpen={handleModalOpened}
                onAfterClose={handleModalClosed}
                shouldCloseOnOverlayClick={true}
              >
                <Container>
                  <FontAwesomeIcon icon={faTimes} onClick={closeIframeModal} />
                  <div
                    className="iframe-in-modal-wrapper"
                    ref={iframeWrapperRef}
                    dangerouslySetInnerHTML={{
                      __html: data.embed.value,
                    }}
                  ></div>
                  <Loader />
                </Container>
              </Modal>
            </>
          )}
        <div className="text">
          <div
            dangerouslySetInnerHTML={{
              __html: data.content?.value,
            }}
          ></div>
          {data.button?.value?.link?.value && (
            <div className="buttons">
              <Link
                className="hv-btn"
                to={formatLink(data.button.value.link.value)}
                target="_blank"
                rel="noreferrer"
              >
                {data.button.value.text.value}
              </Link>
            </div>
          )}
          {image && data.embed?.value && (
            <div className="small-embed-plus-image">
              {!isYoutubeEmbed && (
                <div
                  className="iframe-wrapper"
                  dangerouslySetInnerHTML={{
                    __html: data.embed?.value,
                  }}
                ></div>
              )}
              {isYoutubeEmbed && (
                <>
                  <div
                    className="iframe-thumbnail-wrapper"
                    onClick={() => setIframeModalOpen(true)}
                  >
                    <img src={thumbnail} alt="" />
                    <FontAwesomeIcon icon={faPlay} />
                  </div>
                  <Modal
                    onRequestClose={closeIframeModal}
                    isOpen={iframeModalOpen}
                    className="iframe-modal"
                    onAfterOpen={handleModalOpened}
                    onAfterClose={handleModalClosed}
                    shouldCloseOnOverlayClick={true}
                  >
                    <Container>
                      <FontAwesomeIcon
                        icon={faTimes}
                        onClick={closeIframeModal}
                      />
                      <div
                        className="iframe-in-modal-wrapper"
                        ref={iframeWrapperRef}
                        dangerouslySetInnerHTML={{
                          __html: data.embed.value,
                        }}
                      ></div>
                      <Loader />
                    </Container>
                  </Modal>
                </>
              )}
            </div>
          )}
        </div>
      </div>
      {!data.centered?.value &&
        (!data.bigImage?.value ? (
          <div className="date">
            <p>{data.date ? data.date.value : ""}</p>
          </div>
        ) : (
          <div className="date mobile-date">
            <p>{data.date ? data.date.value : ""}</p>
          </div>
        ))}
      {data.bigImage?.value && (
        <>
          {data.embed?.value && !image && !isYoutubeEmbed && (
            <div
              className="iframe-wrapper big"
              dangerouslySetInnerHTML={{
                __html: data.embed?.value,
              }}
            ></div>
          )}
          {data.embed?.value && !image && isYoutubeEmbed && (
            <>
              <div
                className="iframe-thumbnail-wrapper big"
                onClick={() => setIframeModalOpen(true)}
              >
                <img src={thumbnail} alt="" />
                <FontAwesomeIcon icon={faPlay} />
              </div>
              <Modal
                onRequestClose={closeIframeModal}
                isOpen={iframeModalOpen}
                className="iframe-modal"
                onAfterOpen={handleModalOpened}
                onAfterClose={handleModalClosed}
                shouldCloseOnOverlayClick={true}
              >
                <Container>
                  <FontAwesomeIcon icon={faTimes} onClick={closeIframeModal} />
                  <div
                    className="iframe-in-modal-wrapper"
                    ref={iframeWrapperRef}
                    dangerouslySetInnerHTML={{
                      __html: data.embed.value,
                    }}
                  ></div>
                  <Loader />
                </Container>
              </Modal>
            </>
          )}
          {image && (
            <div className="big-image">
              <GatsbyImage
                image={image}
                onClick={() => setImageModalOpen(image)}
                placeholder="dominantColor"
                alt=""
              />
            </div>
          )}
        </>
      )}

      {last && !data.centered.value && <div className="dot last"></div>}
    </div>
  )
}
